import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import Button from "../shared/button";

const ServiceCard = ({ title, description, images, buttons }) => {
    return (
        <div className="service-card group">
            <div className="service-image relative overflow-hidden bg-primary">
                {images && images[0]?.src ? (
                    <GatsbyImage
                        className="w-full transition delay-300 ease-in-out group-hover:opacity-20"
                        image={getImage(images[0]?.src)}
                        alt={images[0]?.alt || title}
                    />
                ) : (
                    <StaticImage
                        className="w-full transition delay-300 ease-in-out group-hover:opacity-20"
                        src="../../data/images/servies/345x350.png"
                        alt={title}
                    />
                )}
            </div>
            <div className="content mt-8">
                {title && <h2 className="title">{title}</h2>}
                {description && (
                    <p className="description mt-4">{description}</p>
                )}
                {buttons && (
                    <div className="button-group mt-8 space-x-4">
                        {buttons.map(({ id, content, ...props }) => (
                            <Button key={id} {...props}>
                                {content}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

ServiceCard.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            content: PropTypes.string,
        })
    ),
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
            alt: PropTypes.string,
        })
    ),
};

export default ServiceCard;
