import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import PageBreadcrumb from "../components/pagebreadcrumb";
import FeatureSection from "../container/feature";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import ServicesSection from "../container/services-area";

const ServicesPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Services page" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="BENEFITS"
            />
            <FeatureSection data={content?.["features-section"]} />
            <ServicesSection data={content?.["services-section"]} />
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

ServicesPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query ServicesPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "servicesPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
            }
        }
    }
`;
export default ServicesPage;
