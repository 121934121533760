import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "../../components/section-title";
import ServiceCard from "../../components/service-card";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const ServicesSection = ({ data }) => {
    return (
        <section className="services-section section-space-pb border-b relative">
            <div className="bg-shape-bottom-right absolute z-[-1] right-0 bottom-0 w-1/2">
                <GatsbyImage
                    image={getImage(data?.images[0]?.src)}
                    alt={data?.images[0]?.alt || title}
                />
            </div>
            <div className="container-full">
                {data?.section_title && (
                    <SectionTitle
                        heading={data?.section_title?.heading}
                        {...data.section_title}
                    />
                )}
                <div className="mt-10 grid grid-cols-1 md:grid-cols-2 gap-[40px] lg:gap-[60px] xl:gap-[100px]">
                    {data?.items.map((item) => (
                        <ServiceCard
                            key={item.id}
                            images={item.images}
                            title={item.title}
                            description={item.description}
                            buttons={item.buttons}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

ServicesSection.propTypes = {
    data: PropTypes.shape({
        section_title: PropTypes.shape({
            heading: PropTypes.string,
        }),
        items: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            })
        ),
        images: PropTypes.arrayOf(
            PropTypes.shape({
                src: PropTypes.oneOfType([
                    PropTypes.string,
                    PropTypes.shape({}),
                ]),
                alt: PropTypes.string,
            })
        ),
    }),
};

export default ServicesSection;
